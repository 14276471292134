import React from 'react';
import TicTacToeGrid from './TicTacToeGrid';

const LocalH2HMode = () => {
  return (
    <div>
     
      <TicTacToeGrid soloMode={false} />
    </div>
  );
};

export default LocalH2HMode;
